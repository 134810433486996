<template>
  <div class="additionalInfo">
    <el-form :model="additionForm" label-width="140px" ref="additionForm" :inline="true" :rules="rules">
        <div class="title">商品补充信息</div>
        <el-form-item label="国家药品编码" prop="nationalDrugCode">
            <el-input v-model="additionForm.nationalDrugCode" size="medium" clearable placeholder="请输入该药品国家药品编码"/>
        </el-form-item>
        <el-form-item label="英文名称" prop="englishName">
            <el-input v-model="additionForm.englishName" size="medium" clearable placeholder="请输入该药品英文名称"/>
        </el-form-item>
        <el-form-item label="器械管理类别" prop="typeOfDeviceManagement">
            <el-select v-model="additionForm.typeOfDeviceManagement" placeholder="请选择器械管理类别" clearable>
                <el-option
                v-for="item in instrumentOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <div class="title">批件</div>
            <div class="goods-img"> 
                <template>
                    <div class="up-box-bas">
                        <el-upload
                            :action="Actions"
                            list-type="picture-card"
                            :limit="5"
                            :headers="token"
                            :file-list="instructionsList"
                            :before-upload="beforeAvatarUpload"
                            :data="{ type: '1' }"
                            :on-success="successInstruction"
                            :on-exceed="onExceed"
                        >
                            <i class="el-icon-plus"></i>
                            <div slot="file" slot-scope="{ file }">
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url"
                                alt=""
                            />
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                                <i class="el-icon-delete" title="删除" />
                                </span>
                            </span>
                            </div>
                        </el-upload>
                    </div>
                </template>
            </div>
        <div class="title">再注册批件</div>
            <div class="goods-img"> 
                <template>
                    <div class="up-box-bas">
                        <el-upload
                            :action="Actions"
                            list-type="picture-card"
                            :limit="5"
                            :headers="token"
                            :file-list="imageList"
                            :before-upload="beforeAvatarUpload"
                            :data="{ type: '1' }"
                            :on-success="successUpImg"
                            :on-exceed="onExceed"
                        >
                            <i class="el-icon-plus"></i>
                            <div slot="file" slot-scope="{ file }">
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url"
                                alt=""
                            />
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleDel(file)">
                                <i class="el-icon-delete" title="删除" />
                                </span>
                            </span>
                            </div>
                        </el-upload>
                    </div>
                </template>
            </div>
        <div class="title">补充批件</div>
            <div class="goods-img"> 
                <template>
                    <div class="up-box-bas">
                        <el-upload
                            :action="Actions"
                            list-type="picture-card"
                            :limit="5"
                            :headers="token"
                            :file-list="replenishList"
                            :before-upload="beforeAvatarUpload"
                            :data="{ type: '1' }"
                            :on-success="successReplenish"
                            :on-exceed="onExceed"
                        >
                            <i class="el-icon-plus"></i>
                            <div slot="file" slot-scope="{ file }">
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url"
                                alt=""
                            />
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleDelReplenish(file)">
                                <i class="el-icon-delete" title="删除" />
                                </span>
                            </span>
                            </div>
                        </el-upload>
                    </div>
                </template>
            </div>
    </el-form>
    <div class="btn-box">
        <div class="refuse" @click="toBack">返回</div>
        <div class="pass" @click="toSave">保存</div>
        <div class="paseds" v-if="isCreat == 2" @click="toSavecommd">
          保存并更新供应商商品资料
        </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
export default {
    name: 'additionalInfo',
    props:{
        detailsData:{
            type: Object,
            default:{}
        }
    },
    watch:{
        detailsData(val){
            // console.log(val,'aaaaaaaaa')
            for(let key in val){
                if(this.additionForm.hasOwnProperty(key)){
                    this.additionForm[key] = val[key];
                }
            }
            val.batchImagesList.forEach(item => {
                // this.instructionsList.forEach(it =>{
                //     if(it !== item){
                //         this.instructionsList.push({
                //             url:item
                //         })
                //     }
                // })
                this.instructionsList.push({
                            url:item
                        })
            });
            val.registerBatchImagesList.forEach(item => {
                // this.imageList.forEach(it =>{
                //     if(it !== item){
                //         this.imageList.push({
                //             url:item
                //         })
                //     }
                // })
                this.imageList.push({
                            url:item
                        })
            });
            val.extendBatchImagesList.forEach(item => {
                // this.replenishList.forEach(it =>{
                //     if(it !== item){
                //         this.replenishList.push({
                //             url:item
                //         })
                //     }
                // })
                this.replenishList.push({
                            url:item
                        })
            });
        }
    },
    data(){
        return {
            Actions: wholeUrl + "/basis/upload",
            token: { token: getToken()},
            instructionsList:[],
            replenishList:[],
            imageList:[],
            operatorId:'',
            isCreat:1,
            instrumentOptions:[
                {
                    value: '1',
                    label: '第一类'
                },
                {
                    value: '2',
                    label: '第二类'
                },
                {
                    value: '3',
                    label: '第三类'
                }
            ],
            additionForm:{
                nationalDrugCode:'',
                englishName:'',
                typeOfDeviceManagement:'',
                registerBatchImagesList:[],//再注册批件
                batchImagesList:[],//批件
                extendBatchImagesList:[]
            },
            rules: {
                nationalDrugCode: [
                    { min: 1, max: 40, message: '国家药品编码长度最多40个字符', trigger: 'change' }
                ],
                englishName: [
                    { min: 1, max: 40, message: '英文名称长度最多40个字符', trigger: 'change' }
                ],
                nationalDrugCode: [
                    { min: 1, max: 50, message: '国家药品编码长度最多40个字符', trigger: 'change' }
                ],
            }
        }
    },
    created(){
        this.isCreat = this.$route.query.type
    },
    mounted() {
        if(this.isCreat == 1){
            Object.assign(this.additionForm, this.$options.data().additionForm);
        }
        // console.log(this.BasissCopeData,'111111111');
    },
    methods:{
        ...mapActions([
            "saveExtendsInfo",
            "newEdit",
            "getPlatformGoodsDetail"
        ]),
        ...commonIndex.mapActions([
            "syncSupplierGoods", // 商品资料库-更新供应商商品资料
        ]),
        async getDetails() {
            let id = this.$route.query.id
            let data = await this.getPlatformGoodsDetail({id: id})
            // console.log(data, '1111');
        },
        toBack() {
            window.history.go(-1);
        },
        async toSave(){
            if(this.isCreat == 1){
                let res = await this.saveExtendsInfo(this.additionForm)
                if(res.code == '000000'){
                    this.$message({
                        message: '新增成功',
                        type: "success",
                    })
                    this.$emit('replenish', 3)
                }else{
                    this.$message({
                        message: res.message,
                        type: "error",
                    })
                }
            }else{
                const operatorId = sessionStorage.getItem('admin_id');
                this.additionForm.id = this.$route.query.id
                this.additionForm.opType = 'extend'
                this.additionForm.operatorId = operatorId
                let resEdit = await this.newEdit(this.additionForm)
                if(resEdit.code == '000000'){
                    this.$message({
                        message: '保存成功',
                        type: "success",
                    })
                }else{
                    this.$message({
                        message: resEdit.message,
                        type: "error",
                    })
                }
            }
            
        },
        async toSavecommd(){
            const operatorId = sessionStorage.getItem('admin_id');
            this.additionForm.id = this.$route.query.id
            this.additionForm.opType = 'extend'
            this.additionForm.operatorId = operatorId
            let resEdit = await this.newEdit(this.additionForm)
            if(resEdit.code == '000000'){
                let res =  await this.syncSupplierGoods({id:this.$route.query.id});
                if (res.code == "200") {
                    this.$message.success("操作成功");
                }
            }
        },
        successInstruction(data){
            if (data.code == 200) {
                this.instructionsList.push({
                    url: data.data.full_url,
                });
                this.additionForm.batchImagesList.push(data.data.full_url)
                this.$message({
                    message: data.message,
                    type: "success",
                });
            } else {
                this.$message({
                    message: data.message,
                    type: "warning",
                });
            }
        },
        successReplenish(data){
            if (data.code == 200) {
                this.replenishList.push({
                    url: data.data.full_url,
                });
                this.additionForm.extendBatchImagesList.push(data.data.full_url)
                this.$message({
                    message: data.message,
                    type: "success",
                });
            } else {
                this.$message({
                    message: data.message,
                    type: "warning",
                });
            }
        },
        // 成功数据
        successUpImg(data) {
            if (data.code == 200) {
                this.imageList.push({
                    url: data.data.full_url,
                });
                this.additionForm.registerBatchImagesList.push(data.data.full_url)
                this.$message({
                    message: data.message,
                    type: "success",
                });
            } else {
                this.$message({
                    message: data.message,
                    type: "warning",
                });
            }
        },
        handleDel(data){
            this.imageList = this.imageList.filter((item) => {
                return item.url != data.url;
            });
            this.additionForm.registerBatchImagesList = this.additionForm.registerBatchImagesList.filter((item) => {
                return item != data.url;
            });
        },
        handleDelReplenish(data){
            this.replenishList = this.replenishList.filter((item) => {
                return item.url != data.url;
            });
            this.additionForm.extendBatchImagesList = this.additionForm.extendBatchImagesList.filter((item) => {
                return item != data.url;
            });
        },
        // 删除上传的图片
        handleRemove(data) {
            this.instructionsList = this.instructionsList.filter((item) => {
                return item.url != data.url;
            });
            this.additionForm.batchImagesList = this.additionForm.batchImagesList.filter((item) => {
                return item != data.url;
            });
        },
        async beforeAvatarUpload(file) {
            if (file.size / 1024 / 1024 > 50)
                return this.$message.error("上传头像图片大小不能超过 50MB!");
            return await compressImage([file]);
        },
        onExceed(files, fileList){
            this.$message.error("超出图片数量上限");
        },
    }
}
</script>
<style lang='scss' scoped>
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
    .additionalInfo{
        padding-top: 20px;
        .title {
                margin-top: 10px;
                width: 100%;
                font-weight: bold;
                padding-bottom: 16px;
                margin-bottom: 20px;
                border-bottom: dashed 1px #ddd;
                display: flex;
                align-items: center;
            }
        .title::before {
            width: 4px;
            height: 16px;
            background-color: #06b7ae;
            border-radius: 10px;
            display: inline-block;
            content: "";
            margin-right: 10px;
            margin-bottom: -2px;
        }
    }
    .btn-box {
                cursor: pointer;
                margin-top: 3%;
                width: 100%;
                height: 40px;
                display: flex;
                align-content: center;
                justify-content: center;
            div {
                width: 90px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
                text-align: center;
                border-radius: 4px;
                font-weight: normal;
            }
            .pass {
                margin-left: 15px;
                background: #06b7ae;
                color: #ffffff;
            }
            .paseds {
                    width: 200px;
                    background: #06b7ae;
                    color: #ffffff;
                }
            .refuse {
                background: #f2f2f2;
                color: #999999;
            }
        }
        .goods-img {
                padding-top: 10px;
                padding-bottom: 10px;
                width: 100%;
                padding-left: 10px;
                margin-top: 10px;
            }
</style>