<template>
    <div class="specification">
      <div class="title">商品说明书</div>
      <!-- <div class="explain-tba">
        <div :class="activeTab == 1 ? 'chose' : ''" @click="handleChange(1)">表格模式</div>
        <div :class="activeTab == 2 ? 'chose' : ''" @click="handleChange(2)">富文本模式</div>
      </div> -->
      <div class="execl-box" v-if="contentType == 1">
        <div class="row-box" style="border-top: 1px solid #d4d7d9;">
            <div class="row-title">适应症/功能主治</div>
            <div class="row-value">{{ specificationForm.indications || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">用法用量</div>
            <div class="row-value">{{ specificationForm.usage || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">不良反应</div>
            <div class="row-value">{{ specificationForm.adverseReactions || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">禁忌</div>
            <div class="row-value">{{ specificationForm.taboo || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">注意事项</div>
            <div class="row-value">{{ specificationForm.attentions || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">孕妇及哺乳期用药</div>
            <div class="row-value">{{ specificationForm.isForPregnantWoman || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">儿童用药</div>
            <div class="row-value">{{ specificationForm.isForKid || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">老人用药</div>
            <div class="row-value">{{ specificationForm.isForOld || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">药物相互作用</div>
            <div class="row-value">{{ specificationForm.drugInteraction || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">药理毒理</div>
            <div class="row-value">{{ specificationForm.drugToxicology || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">药代动力学</div>
            <div class="row-value">{{ specificationForm.pharmacokinetics || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">主要成分</div>
            <div class="row-value">{{ specificationForm.ingredients || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">性状</div>
            <div class="row-value">{{ specificationForm.character || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">包装材质</div>
            <div class="row-value">{{ specificationForm.packagingMaterial || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">说明书有效期</div>
            <div class="row-value">{{ specificationForm.validityPeriod || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">适宜人群</div>
            <div class="row-value">{{ specificationForm.suitablePopulation || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">不适宜人群</div>
            <div class="row-value">{{ specificationForm.noSuitablePopulation || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">药理作用</div>
            <div class="row-value">{{ specificationForm.pharmacologicAction || '/' }}</div>
        </div>
        <div class="row-box">
            <div class="row-title">药物过量</div>
            <div class="row-value">{{ specificationForm.drugOverdose || '/' }}</div>
        </div>
      </div>
      <div style="padding-bottom: 100px;" v-if="contentType == 2">
        <pre><Editor v-model="contents" class="Editor" /></pre>
      </div>
      <div class="btn-box">
        <div class="refuse" @click="toBack">返回</div>
      </div>
    </div>
  </template>
  
<script>
  import { createNamespacedHelpers } from "vuex";
  const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
  const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
  import Editor from "@/components/Editor/index.vue"; //商品说明书
  export default {
      name: 'specification',
      components: {
            Editor,
        },
        props:{
            detailsData:{
                type: Object,
                default:{}
            }
        },
        watch:{
            detailsData(val){
                for(let key in val.contentObj){
                    if(this.specificationForm.hasOwnProperty(key)){
                        this.specificationForm[key] = val.contentObj[key];
                    }
                }
                this.contentType = val.contentType;
                this.contents = val.content
            }
        },
      data(){
          return {
                activeTab:1,
                contentType: null,
                specificationForm:{
                  indications:'',
                  usage:'',
                  adverseReactions:'',
                  taboo:'',
                  attentions:'',
                  isForPregnantWoman:'',
                  isForKid:'',
                  isForOld:'',
                  drugInteraction:'',
                  drugToxicology:'',
                  pharmacokinetics:'',
                  ingredients:'',
                  character:'',
                  packagingMaterial:'',
                  validityPeriod:'',
                  suitablePopulation:'',
                  noSuitablePopulation:'',
                  pharmacologicAction:'',
                  drugOverdose:'',
                  expirationDate:''
                },
                contents:''
          }
      },
      created(){
          this.isCreat = this.$route.query.type
      },
      methods:{
        handleChange(type){
            this.activeTab = type
        },
        toBack() {
            window.history.go(-1);
        },
      }
  }
  </script>
  
  <style lang="scss" scoped>
      .specification{
          padding-top: 10px;
          .execl-box{
            width: 100%;
            .row-box{
                display: flex;
                .row-title{
                    width: 250px;
                    padding: 15px 10px;
                    border: 1px solid #d4d7d9;
                    border-top: 0;
                    background-color: #f7f8fa;
                    color: #6d7687;
                    font-size: 14px;
                    text-align: center;
                }
                .row-value{
                    flex: 1;
                    padding: 15px 10px;
                    border: 1px solid #d4d7d9;
                    border-left: none;
                    border-top: 0;
                    font-size: 14px;
                    color: #2c384d;
                }   
            }
          }
          .explain-tba{
            display: flex;
            margin-bottom: 10px;
            > div {
                width: 100px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #333;
                font-size: 14px;
                margin-right: 10px;
                cursor: pointer;
            }
            .chose {
                background-color: #06b7ae;
                color: #fff;
                border-radius: 4px;
            }
          }
          .title {
                  margin-top: 10px;
                  width: 100%;
                  font-weight: bold;
                  padding-bottom: 16px;
                  margin-bottom: 20px;
                  border-bottom: dashed 1px #ddd;
                  display: flex;
                  align-items: center;
              }
          .title::before {
              width: 4px;
              height: 16px;
              background-color: #06b7ae;
              border-radius: 10px;
              display: inline-block;
              content: "";
              margin-right: 10px;
              margin-bottom: -2px;
          }
          .btn-box {
                cursor: pointer;
                // margin-top: 2%;
                width: 100%;
                height: 40px;
                display: flex;
                align-content: center;
                justify-content: center;
            div {
                width: 90px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
                text-align: center;
                border-radius: 4px;
                font-weight: normal;
            }
            .pass {
                margin-left: 15px;
                background: #06b7ae;
                color: #ffffff;
            }
            .refuse {
                background: #f2f2f2;
                color: #999999;
            }
        }
      }
  </style>