<template>
  <div class="basicGoodsInfo">
    <div v-show="isLoading">
        <el-form :model="basicForm" label-width="150px" ref="basicForm" :inline="true" :rules="rules">
            <div class="goods-info">
                <div class="info-left">
                    <div class="title">商品基本信息</div>
                    <el-form-item label="商品名称" prop="name">
                        <el-input v-model.trim="basicForm.name" size="medium" clearable placeholder="请输入商品名称"/>
                    </el-form-item>
                    <el-form-item label="通用名" prop="commonName">
                        <el-input v-model.trim="basicForm.commonName" size="medium" clearable placeholder="请输入商品通用名称" />
                    </el-form-item>
                    <el-form-item label="规格" prop="specification">
                        <el-input v-model.trim="basicForm.specification" size="medium" clearable placeholder="请输入商品规格" />
                    </el-form-item>
                    <el-form-item label="拼音名" prop="pinyin">
                        <el-input v-model.trim="basicForm.pinyin" size="medium" clearable placeholder="请输入商品拼音名称" />
                    </el-form-item>
                    <el-form-item label="商品单位" prop="unit">
                        <el-input v-model.trim="basicForm.unit" size="medium" clearable placeholder="请输入商品包装单位" />
                    </el-form-item>
                    <el-form-item label="品牌" prop="brand">
                        <el-input v-model.trim="basicForm.brand" size="medium" clearable placeholder="请输入商品品牌" />
                    </el-form-item>
                    <el-form-item label="商品类型" prop="category">
                        <el-radio-group v-model="basicForm.category">
                            <el-radio :label="0">普药</el-radio>
                            <el-radio :label="1">中药</el-radio>
                            <el-radio :label="2">非药品</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="本位码" prop="standardCode">
                        <el-input v-model.trim="basicForm.standardCode" size="medium" clearable placeholder="请输入商品本位码" />
                    </el-form-item>
                    <el-form-item label="条形码（69码）" prop="barCode">
                        <el-input v-model.trim="basicForm.barCode" size="medium" clearable placeholder="请输入商品69码" />
                    </el-form-item>
                    <el-form-item label="执行标准" prop="executionStandard">
                        <el-input v-model.trim="basicForm.executionStandard" size="medium" clearable placeholder="请输入商品执行标准" />
                    </el-form-item>
                    <el-form-item label="国家医保码" prop="healthCode">
                        <el-input v-model.trim="basicForm.healthCode" size="medium" clearable placeholder="请输入国家医保码" />
                    </el-form-item>
                    <el-form-item label="产地" prop="placeOrigin">
                        <el-input v-model.trim="basicForm.placeOrigin" size="medium" clearable placeholder="请输入商品产地" />
                    </el-form-item>
                    <el-form-item label="批准文号" prop="approvalNumber">
                        <el-input v-model.trim="basicForm.approvalNumber" size="medium" clearable placeholder="请输入商品批准文号" />
                    </el-form-item>
                    <el-form-item label="剂型" prop="dosageForm">
                        <el-input v-model.trim="basicForm.dosageForm" size="medium" clearable placeholder="请输入商品剂型" />
                    </el-form-item>
                    <el-form-item label="经营类别" prop="businessScopeId">
                        <el-select clearable v-model="basicForm.businessScopeId" placeholder="请选择商品类别" size="medium">
                            <el-option
                                v-for="elem in BasissCopeData.Newbusiness_scope_id"
                                :key="elem.id"
                                :label="elem.name"
                                :value="elem.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品分类" prop="goodsCategoryIdList[0]">
                        <div v-for="(val, i) in basicForm.goodsCategoryIdList" :key="i">
                            <div>
                                <el-cascader
                                    class="category"
                                    v-model="basicForm.goodsCategoryIdList[i]"
                                    :options="BasissCopeData.Newgoods_categoryList"
                                    :props="propsData"
                                    placeholder="请选择商品分类"
                                    size="medium"
                                    clearable
                                ></el-cascader>
                                <span class="exceed_color font-size-12" @click="AddClassify()" >添加</span>
                                <span @click="removeClassify(i)" v-if="i != 0" class="exceed_color font-size-12">删除</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="外部ID" prop="externalId" v-if="isCreat == 2">
                        <el-input v-model.trim="basicForm.externalId" disabled size="medium" clearable placeholder="请输入外部ID" />
                    </el-form-item>
                </div>
                <div class="info-rigth">
                    <div class="title">商品厂家信息</div>
                    <el-form-item label="生产厂家" prop="manufacturer">
                        <el-input v-model.trim="basicForm.manufacturer" size="medium" clearable />
                    </el-form-item>
                    <el-form-item label="药品上市许可持有人" prop="marketingAuthorizationHolder">
                        <el-input v-model.trim="basicForm.marketingAuthorizationHolder" size="medium" clearable />
                    </el-form-item>
                    <el-form-item label="厂家简称">
                        <el-input v-model.trim="basicForm.shortName" size="medium" clearable />
                    </el-form-item>
                    <el-form-item label="上市许可持有人地址">
                        <el-input v-model.trim="basicForm.marketingAuthorizationHolderAddress" size="medium" clearable />
                    </el-form-item>
                    <el-form-item label="厂家地址">
                        <el-input v-model.trim="basicForm.productionAddress" size="medium" clearable />
                    </el-form-item>
                    <div class="title">商品标签信息</div>
                    <el-form-item label="是否集采" prop="isCollect">
                        <div style="width: 200px;">
                            <el-radio-group v-model="basicForm.isCollect">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否OTC" prop="isOtc">
                        <div style="width: 200px;">
                            <el-radio-group v-model="basicForm.isOtc">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否有追朔码">
                        <div style="width: 200px;">
                            <el-radio-group v-model="basicForm.traceCode">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="OTC类型" prop="otcType" v-if="basicForm.isOtc == 1">
                        <div style="width: 200px;">
                            <el-radio-group v-model="basicForm.otcType">
                                <el-radio :label="1">甲类</el-radio>
                                <el-radio :label="2">乙类</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <div class="title">
                        <span>商品图片</span>
                        <span v-if="isCreat == 2" style="margin-left: 10px;">(<span style="color: red;font-weight: 400;font-size: 15px;">最近更新时间{{ basicForm.pictureUpdateTime }}</span>)</span>
                    </div>
                    <div class="goods-img">
                        <!-- <template>
                            <el-image
                            v-for="(item, index) in imgDate"
                            :key="index"
                            style="width: 100px; height: 100px"
                            :src="item"
                            :preview-src-list="[item]"
                            ></el-image>
                        </template> -->
                        <!-- :disabled="isCreat == 2" -->
                        <template>
                            <div class="up-box-bas">
                                <el-upload
                                    :action="Actions"
                                    list-type="picture-card"
                                    :limit="6"
                                    :headers="token"
                                    :file-list="imageList"
                                    :before-upload="beforeAvatarUpload"
                                    :data="{ type: '1' }"
                                    :on-success="successUpImg"
                                    :on-exceed="onExceed"
                                >
                                    <i class="el-icon-plus"></i>
                                    <div slot="file" slot-scope="{ file }">
                                    <img
                                        class="el-upload-list__item-thumbnail"
                                        :src="file.url"
                                        alt=""
                                    />
                                    <span class="el-upload-list__item-actions">
                                        <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                                        <i class="el-icon-delete" title="删除" />
                                        </span>
                                    </span>
                                    </div>
                                </el-upload>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="btn-box">
            <div class="refuse" @click="toBack">返回</div>
            <div class="pass" @click="toSave">保存</div>
            <div class="paseds" v-if="isCreat == 2" @click="toSavecommd">
              保存并更新供应商商品资料
            </div>
        </div>
    </div>
    <div v-show="!isLoading">
        <Loading />
        <div class="loading-text">
            正在校验当前商品是否已存在，请稍等
        </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
import Loading from "./loading.vue";
export default {
    name: 'basicGoodsInfo',
    components: {
        Loading
    },
    props:{
        detailsData:{
            type: Object,
            default:{}
        }
    },
    watch:{
        detailsData(val){
            for(let key in val){
                if(this.basicForm.hasOwnProperty(key)){
                    this.basicForm[key] = val[key];
                }
            }
            if(this.basicForm.businessScopeId == 0){
                this.basicForm.businessScopeId = ''
            }
            if(val.goodsCategoryIdList.length == 0){
                this.basicForm.goodsCategoryIdList = [[]]
            }
            val.pictureUrlList.forEach(item => {
                // this.imageList.forEach(it => {
                //     if(item != it.url){
                //         this.imageList.push({
                //             url:item
                //         })
                //     }
                // })
                this.imageList.push({
                            url:item
                        })
            });
        }
    },
    data(){
        return{
            isLoading: true,
            Actions: wholeUrl + "/basis/upload",
            token: { token: getToken() },
            imageList: [],
            propsData: {
                value: "id",
                label: "category_name",
                children: "son",
                checkStrictly: true,
                //级联选择器配置项
            },
            isCreat:1,
            basicForm:{
                name:'',
                commonName:'',
                specification:'',
                pinyin:'',
                unit:'',
                brand:'',
                category:0,
                standardCode:'',
                barCode:'',
                executionStandard:'',
                healthCode:'',
                placeOrigin:'',
                approvalNumber:'',
                dosageForm:'',
                businessScopeId:'',
                manufacturer:'',
                marketingAuthorizationHolder:'',
                shortName:'',
                marketingAuthorizationHolderAddress:'',
                productionAddress:'',
                isCollect:0,
                isOtc: 1,
                traceCode:0,
                otcType:1,
                pictureUrlList:[],
                goodsCategoryIdList:[[]],
                externalId:'',
                pictureUpdateTime:''
            },
            //{ pattern: /^[0-9]*$/g, message: '国家医保码必须为数字',trigger: 'change'}
            rules: {
                name: [
                    { required: true, message: '商品名称必填', trigger: 'change' },
                    { min: 1, max: 50, message: '长度最多50个字符', trigger: 'change' }
                ],
                specification: [
                    { required: true, message: '规格必填', trigger: 'change' },
                    { min: 1, max: 40, message: '长度最多40个字符', trigger: 'change' }
                ],
                unit: [
                    { required: true, message: '商品单位必填', trigger: 'change' },
                    { min: 1, max: 40, message: '长度最多40个字符', trigger: 'change' }
                ],
                category: [
                    { required: true, message: '商品类型必填', trigger: 'change' },
                ],
                barCode: [
                    { min: 1, max: 40, message: '长度最多40个字符', trigger: 'change' }
                ],
                healthCode: [
                    { min: 1, max: 30, message: '长度最多30个字符', trigger: 'change' },
                ],
                approvalNumber: [
                    { min: 1, max: 50, message: '长度最多50个字符', trigger: 'change' }
                ],
                commonName: [
                    { min: 1, max: 50, message: '通用名长度最多50个字符', trigger: 'change' }
                ],
                pinyin: [
                    { min: 1, max: 100, message: '拼音名长度最多100个字符', trigger: 'change' }
                ],
                brand: [
                    { min: 1, max: 50, message: '品牌最多50个字符', trigger: 'change' }
                ],
                standardCode: [
                    { min: 1, max: 50, message: '本位码长度最多50个字符', trigger: 'change' }
                ],
                executionStandard: [
                    { min: 1, max: 50, message: '执行标准长度最多50个字符', trigger: 'change' }
                ],
                placeOrigin: [
                    { min: 1, max: 70, message: '产地长度最多70个字符', trigger: 'change' }
                ],
                dosageForm: [
                    { min: 1, max: 40, message: '剂型长度最多40个字符', trigger: 'change' }
                ],
                businessScopeId:[
                    { required: true, message: '经营类别必选', trigger: 'change' },
                ],
                manufacturer: [
                    { min: 1, max: 70, message: '生产厂家最多70个字符', trigger: 'change' }
                ],
                goodsCategoryIdList: [
                    { required: true, message: '商品分类必填', trigger: 'change' },
                ],
            }
        }
    },
    computed: {
        ...commonIndex.mapState(["BasissCopeData"]),
    },
    created(){
        this.isCreat = this.$route.query.type
    },
    mounted() {
        // if(this.isCreat == 1){
        //     Object.assign(this.basicForm, this.$options.data().basicForm);
        // }
        // console.log(this.isCreat,'this.isCreat');
    },
    methods: {
        ...mapActions([
            "saveBaseInfo",
            "newEdit",
            "getPlatformGoodsDetail"
        ]),
        ...commonIndex.mapActions([
            "syncSupplierGoods", // 商品资料库-更新供应商商品资料
        ]),
        async getDetails() {
            let id = this.$route.query.id
            let data = await this.getPlatformGoodsDetail({id: id})
        },
        toBack() {
            window.history.go(-1);
        },
        async toSave(){
            this.$refs.basicForm.validate(async (valid) =>{
                if(valid){
                    if(this.isCreat == 1){
                        this.basicForm.operatorId = sessionStorage.getItem('admin_id');
                        this.isLoading = false;
                        let res = await this.saveBaseInfo(this.basicForm)
                        this.isLoading = true
                        if(res.code == '000000'){
                            this.$message({
                                message: '新增成功',
                                type: "success",
                            })
                            this.$emit('creteSuccessed', 2,res.content)
                        }else{
                            this.$message({
                                message: res.message,
                                type: "error",
                            })
                        }
                    }else {
                        const operatorId = sessionStorage.getItem('admin_id');
                        this.basicForm.id= this.$route.query.id
                        this.basicForm.opType = 'info'
                        this.basicForm.operatorId = operatorId
                        let resEdit = await this.newEdit(this.basicForm)
                        if(resEdit.code == '000000'){
                            this.$message({
                                message: '保存成功',
                                type: "success",
                            })
                        }else{
                            this.$message({
                                message: resEdit.message,
                                type: "error",
                            })
                        }
                    }
                }else{
                    return this.$message({
                                message: '请完整填写商品信息',
                                type: "warning",
                            });
                }
            })
        },
        async toSavecommd(){
            this.$refs.basicForm.validate(async (valid) =>{
                if(valid){
                    const operatorId = sessionStorage.getItem('admin_id');
                    this.basicForm.id= this.$route.query.id
                    this.basicForm.opType = 'info'
                    this.basicForm.operatorId = operatorId
                    let resEdit = await this.newEdit(this.basicForm)
                    if(resEdit.code == '000000'){
                        let res =  await this.syncSupplierGoods({id:this.$route.query.id});
                        if (res.code == "200") {
                        this.$message.success("操作成功");
                        }
                    }
                }
            })
        },
        //删除分类
        removeClassify(index) {
            this.basicForm.goodsCategoryIdList.splice(index, 1);
        },
        // 添加分类
        AddClassify(key) {
            if (this.basicForm.goodsCategoryIdList.length < 10) {
                this.basicForm.goodsCategoryIdList.push([]);
            }
        },
        // 成功数据
        successUpImg(data) {
            if (data.code == 200) {
                this.imageList.push({
                    url: data.data.full_url,
                });
                this.basicForm.pictureUrlList.push(data.data.full_url)
                this.$message({
                    message: data.message,
                    type: "success",
                });
            } else {
                this.$message({
                    message: data.message,
                    type: "warning",
                });
            }
        },
        // 删除上传的图片
        handleRemove(data) {
            this.imageList = this.imageList.filter((item) => {
                return item.url != data.url;
            });
            this.basicForm.pictureUrlList = this.basicForm.pictureUrlList.filter((item) => {
                return item != data.url;
            });
        },
        async beforeAvatarUpload(file) {
            if (file.size / 1024 / 1024 > 50)
                return this.$message.error("上传头像图片大小不能超过 50MB!");
            return await compressImage([file]);
        },
        onExceed(files, fileList){
            this.$message.error("超出图片数量上限");
        },
    }
}
</script>
<style lang='scss' scoped>
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
    .basicGoodsInfo{
        padding-top: 20px;
        .goods-info{
            display: flex;
            justify-content: space-between;

            .info-left{
                width: 48%;
            }
            .info-rigth{
                width: 48%;
            }
            .goods-img {
                padding-top: 10px;
                padding-bottom: 10px;
                width: 100%;
                padding-left: 10px;
                margin-top: 10px;
            }
            .title {
                margin-top: 10px;
                width: 100%;
                font-weight: bold;
                padding-bottom: 16px;
                margin-bottom: 10px;
                border-bottom: dashed 1px #ddd;
                display: flex;
                align-items: center;
            }
            .title::before {
                width: 4px;
                height: 16px;
                background-color: #06b7ae;
                border-radius: 10px;
                display: inline-block;
                content: "";
                margin-right: 10px;
                margin-bottom: -2px;
            }
        }
        .btn-box {
                cursor: pointer;
                margin-top: 3%;
                width: 100%;
                height: 40px;
                display: flex;
                align-content: center;
                justify-content: center;
            div {
                width: 90px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
                text-align: center;
                border-radius: 4px;
                font-weight: normal;
            }
            .pass {
                margin-left: 15px;
                background: #06b7ae;
                color: #ffffff;
            }
            .paseds {
                width: 200px;
                background: #06b7ae;
                color: #ffffff;
            }
            .refuse {
                background: #f2f2f2;
                color: #999999;
            }
        }
        .loading-text{
            margin-top: 20px;
            font-size: 16px;
            // font-weight: bold;
            text-align: center;
        }   
    }
</style>