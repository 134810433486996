<template>
    <div class="specification">
      <div class="title">商品说明书</div>
      <div class="explain-tba">
        <div :class="activeTab == 1 ? 'chose' : ''" @click="handleChange(1)">表格模式</div>
        <div :class="activeTab == 2 ? 'chose' : ''" @click="handleChange(2)">富文本模式</div>
      </div>
      <div class="execl-box" v-if="activeTab == 1">
        <el-form :model="specificationForm" label-width="140px" ref="specificationForm">
          <el-form-item label="适应症/功能主治" prop="indications">
            <el-input type="textarea" v-model="specificationForm.indications" placeholder="请输入适应症/功能主治"></el-input>
          </el-form-item>
          <el-form-item label="用法用量" prop="usage">
            <el-input type="textarea" v-model="specificationForm.usage" placeholder="请输入用法用量"></el-input>
          </el-form-item>
          <el-form-item label="不良反应" prop="adverseReactions">
            <el-input type="textarea" v-model="specificationForm.adverseReactions" placeholder="请输入不良反应"></el-input>
          </el-form-item>
          <el-form-item label="禁忌" prop="taboo">
            <el-input type="textarea" v-model="specificationForm.taboo" placeholder="请输入禁忌"></el-input>
          </el-form-item>
          <el-form-item label="注意事项" prop="attentions">
            <el-input type="textarea" v-model="specificationForm.attentions" placeholder="请输入注意事项"></el-input>
          </el-form-item>
          <el-form-item label="孕妇及哺乳期用药" prop="isForPregnantWoman">
            <el-input type="textarea" v-model="specificationForm.isForPregnantWoman" placeholder="请输入孕妇及哺乳期用药"></el-input>
          </el-form-item>
          <el-form-item label="儿童用药" prop="isForKid">
            <el-input type="textarea" v-model="specificationForm.isForKid" placeholder="请输入儿童用药"></el-input>
          </el-form-item>
          <el-form-item label="老人用药" prop="isForOld">
            <el-input type="textarea" v-model="specificationForm.isForOld" placeholder="请输入老人用药"></el-input>
          </el-form-item>
          <el-form-item label="药物相互作用" prop="drugInteraction">
            <el-input type="textarea" v-model="specificationForm.drugInteraction" placeholder="请输入药物相互作用"></el-input>
          </el-form-item>
          <el-form-item label="药理毒理" prop="drugToxicology">
            <el-input type="textarea" v-model="specificationForm.drugToxicology" placeholder="请输入药理毒理"></el-input>
          </el-form-item>
          <el-form-item label="药代动力学" prop="pharmacokinetics">
            <el-input type="textarea" v-model="specificationForm.pharmacokinetics" placeholder="请输入药代动力学"></el-input>
          </el-form-item>
          <el-form-item label="主要成分" prop="ingredients">
            <el-input type="textarea" v-model="specificationForm.ingredients" placeholder="请输入主要成分"></el-input>
          </el-form-item>
          <el-form-item label="性状" prop="character">
            <el-input type="textarea" v-model="specificationForm.character" placeholder="请输入性状"></el-input>
          </el-form-item>
          <el-form-item label="包装材质" prop="packagingMaterial">
            <el-input type="textarea" v-model="specificationForm.packagingMaterial" placeholder="请输入包装材质"></el-input>
          </el-form-item>
          <el-form-item label="说明书有效期" prop="validityPeriod">
            <el-date-picker v-model="specificationForm.validityPeriod" value-format="yyyy-MM" type="month" placeholder="请选择说明书有效期(月)"></el-date-picker>
          </el-form-item>
          <el-form-item label="适宜人群" prop="suitablePopulation">
            <el-input type="textarea" v-model="specificationForm.suitablePopulation" placeholder="请输入适宜人群"></el-input>
          </el-form-item>
          <el-form-item label="不适宜人群" prop="noSuitablePopulation">
            <el-input type="textarea" v-model="specificationForm.noSuitablePopulation" placeholder="请输入不适宜人群"></el-input>
          </el-form-item>
          <el-form-item label="药理作用" prop="pharmacologicAction">
            <el-input type="textarea" v-model="specificationForm.pharmacologicAction" placeholder="请输入药理作用"></el-input>
          </el-form-item>
          <el-form-item label="药物过量" prop="drugOverdose">
            <el-input type="textarea" v-model="specificationForm.drugOverdose" placeholder="请输入药物过量"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeTab == 2">
        <pre><Editor v-model="contents" class="Editor" /></pre>
      </div>
      <div class="btn-box">
        <div class="refuse" @click="toBack">返回</div>
        <div class="pass" @click="toSave">保存</div>
        <div class="paseds" v-if="isCreat == 2" @click="toSavecommd">
          保存并更新供应商商品资料
        </div>
      </div>
    </div>
  </template>
  
<script>
  import { createNamespacedHelpers } from "vuex";
  const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
  const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
  import Editor from "@/components/Editor/index.vue"; //商品说明书
  export default {
      name: 'specification',
      components: {
            Editor,
        },
        props:{
            detailsData:{
                type: Object,
                default:{}
            }
        },
        watch:{
            detailsData(val){
                // console.log(val,'55555555555');
                for(let key in val.contentObj){
                    if(this.specificationForm.hasOwnProperty(key)){
                        this.specificationForm[key] = val.contentObj[key];
                    }
                }
                this.contents = val.content
            }
        },
      data(){
          return {
                activeTab:1,
                specificationForm:{
                  indications:'',
                  usage:'',
                  adverseReactions:'',
                  taboo:'',
                  attentions:'',
                  isForPregnantWoman:'',
                  isForKid:'',
                  isForOld:'',
                  drugInteraction:'',
                  drugToxicology:'',
                  pharmacokinetics:'',
                  ingredients:'',
                  character:'',
                  packagingMaterial:'',
                  validityPeriod:'',
                  suitablePopulation:'',
                  noSuitablePopulation:'',
                  pharmacologicAction:'',
                  drugOverdose:'',
                  validityPeriod:''
                },
                contents:''
          }
      },
      created(){
          this.isCreat = this.$route.query.type
      },
      mounted() {
        if(this.isCreat == 1){
            Object.assign(this.specificationForm, this.$options.data().specificationForm);
        }
      },
      methods:{
        ...mapActions([
            "saveInstructions",
            "newEdit",
            "getPlatformGoodsDetail"
        ]),
        ...commonIndex.mapActions([
            "syncSupplierGoods", // 商品资料库-更新供应商商品资料
        ]),
        async getDetails() {
            let id = this.$route.query.id
            let data = await this.getPlatformGoodsDetail({id: id})
            // console.log(data, '1111');
        },
        handleChange(type){
            this.activeTab = type
        },
        toBack() {
            window.history.go(-1);
        },
        async toSave(){
          if(this.isCreat == 1){
            let params = {
              contentObj:this.specificationForm,
              contents:this.contents,
              contentType:this.activeTab
            }
            let res = await this.saveInstructions(params);
              if(res.code =='000000'){
                this.$message({
                      message: '新增成功',
                      type: "success",
                })
              }else{
                this.$message({
                    message: res.message,
                    type: "error",
                })
              }
          }else{
            const operatorId = sessionStorage.getItem('admin_id');
            let param = {
              contentObj:this.specificationForm,
              contents:this.contents,
              contentType:this.activeTab,
              opType:'instructions',
              id:this.$route.query.id,
              operatorId:operatorId
            }
            let resEdit = await this.newEdit(param)
            if(resEdit.code == '000000'){
              this.$message({
                  message: '保存成功',
                  type: "success",
              })
            }else{
              this.$message({
                    message: resEdit.message,
                    type: "error",
                })
            }
          }
        },
        async toSavecommd(){
           const operatorId = sessionStorage.getItem('admin_id');
            let param = {
              contentObj:this.specificationForm,
              contents:this.contents,
              contentType:this.activeTab,
              opType:'instructions',
              id:this.$route.query.id,
              operatorId:operatorId
            }
            let resEdit = await this.newEdit(param)
            if(resEdit.code == '000000'){
              let res =  await this.syncSupplierGoods({id:this.$route.query.id});
              if (res.code == "200") {
                this.$message.success("操作成功");
              }
            }
        }
      }
  }
  </script>
  
  <style lang="scss" scoped>
      .specification{
          padding-top: 10px;
          .execl-box{
            width: 100%;
          }
          .explain-tba{
            display: flex;
            margin-bottom: 10px;
            > div {
                width: 100px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #333;
                font-size: 14px;
                margin-right: 10px;
                cursor: pointer;
            }
            .chose {
                background-color: #06b7ae;
                color: #fff;
                border-radius: 4px;
            }
          }
          .title {
                  margin-top: 10px;
                  width: 100%;
                  font-weight: bold;
                  padding-bottom: 16px;
                  margin-bottom: 20px;
                  border-bottom: dashed 1px #ddd;
                  display: flex;
                  align-items: center;
              }
          .title::before {
              width: 4px;
              height: 16px;
              background-color: #06b7ae;
              border-radius: 10px;
              display: inline-block;
              content: "";
              margin-right: 10px;
              margin-bottom: -2px;
          }
          .btn-box {
                cursor: pointer;
                margin-top: 6%;
                width: 100%;
                height: 40px;
                display: flex;
                align-content: center;
                justify-content: center;
            div {
                width: 90px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
                text-align: center;
                border-radius: 4px;
                font-weight: normal;
            }
            .pass {
                margin-left: 15px;
                background: #06b7ae;
                color: #ffffff;
            }
            .paseds {
                width: 200px;
                background: #06b7ae;
                color: #ffffff;
            }
            .refuse {
                background: #f2f2f2;
                color: #999999;
            }
        }
      }
  </style>